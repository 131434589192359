import React from 'react';
import { useNavigate } from 'react-router-dom';
import Device from "../assets/device.png";
import Home from "../assets/Home.png";
import Map from "../assets/maps.png";
import "./menu.scss";

const Menu = () => {
    const navigate = useNavigate();

    const handleNavigation = (path) => {
        navigate(path);
    };

    return (
        <div className='menu'>
            <div className="icons">
                <div className="icon" onClick={() => handleNavigation('/home')}>
                    <img src={Home} alt="Home" />
                    <div className="text">Home</div>
                </div>
                {/* <div className="icon" onClick={() => handleNavigation('/map')}>
                    <img src={Map} alt="Map" />
                    <div className="text">Map</div>
                </div> */}
                <div className="icon" onClick={() => handleNavigation('/display')}>
                    <img src={Device} alt="Device" />
                    <div className="text">Device</div>
                </div>
            </div>
        </div>
    );
}

export default Menu;
