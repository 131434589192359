// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/ProtoMono-Regular.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'ProtoMono';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff");
  font-weight: normal;
  font-style: normal; }

html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #100F15;
  font-family: 'ProtoMono', sans-serif; }
`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,2DAAyD;EACzD,mBAAmB;EACnB,kBAAkB,EAAA;;AAGpB;;EAEE,WAAW;EACX,aAAa;EACb,SAAS;EACT,UAAU;EACV,yBAAyB;EACzB,oCAAoC,EAAA","sourcesContent":["@font-face {\n  font-family: 'ProtoMono';\n  src: url('./fonts/ProtoMono-Regular.woff') format('woff');\n  font-weight: normal;\n  font-style: normal;\n}\n\nhtml,\nbody {\n  width: 100%;\n  height: 100vh;\n  margin: 0;\n  padding: 0;\n  background-color: #100F15;\n  font-family: 'ProtoMono', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
