import React from 'react'
import "./status.scss";

const Status = () => {
  return (
      <div className='status-container'>
          <div class="status-name">
              Status
          </div>
          <div class="percentage-box">
              <div class="percentage">
                  38%
              </div>
              <div class="chart">
                  
              </div>
        </div>
    </div>
  )
}

export default Status;