// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container {
  width: 90%;
  height: 83%;
  margin: auto;
  margin-top: 30px;
  border-radius: 10px;
  background: #d84b40;
  background: linear-gradient(90deg, #d84b40 0%, #802e2a 75%, #732b27 100%); }
  .form-container .device-svg {
    height: 90px; }
  .form-container .form-card {
    z-index: 1;
    color: #2F2F32;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 8px; }
    .form-container .form-card .device-id {
      font-size: 8px; }
    .form-container .form-card .form-content {
      display: flex;
      gap: 4px;
      flex-direction: row; }
      .form-container .form-card .form-content .cont {
        display: flex;
        flex-direction: row; }
      .form-container .form-card .form-content .form-img {
        display: inline; }
      .form-container .form-card .form-content .form-data {
        margin: 4px;
        font-size: 5px; }
`, "",{"version":3,"sources":["webpack://./src/components/formdata.scss"],"names":[],"mappings":"AAAA;EACI,UAAU;EACV,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,mBAA4B;EAC5B,yEAAgH,EAAA;EAPpH;IAUQ,YAAY,EAAA;EAVpB;IAeQ,UAAU;IACV,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,WAAW,EAAA;IAtBnB;MAyBY,cAAc,EAAA;IAzB1B;MA6BY,aAAa;MACb,QAAQ;MACR,mBAAmB,EAAA;MA/B/B;QAkCgB,aAAa;QACb,mBAAmB,EAAA;MAnCnC;QAwCgB,eAAe,EAAA;MAxC/B;QA6CgB,WAAW;QACX,cAAc,EAAA","sourcesContent":[".form-container {\n    width: 90%;\n    height: 83%;\n    margin: auto;\n    margin-top: 30px;\n    border-radius: 10px;\n    background: rgb(216, 75, 64);\n    background: linear-gradient(90deg, rgba(216, 75, 64, 1) 0%, rgba(128, 46, 42, 1) 75%, rgba(115, 43, 39, 1) 100%);\n\n    .device-svg {\n        height: 90px;\n\n    }\n\n    .form-card {\n        z-index: 1;\n        color: #2F2F32;\n\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n        margin: 8px;\n\n        .device-id {\n            font-size: 8px;\n        }\n\n        .form-content {\n            display: flex;\n            gap: 4px;\n            flex-direction: row;\n\n            .cont {\n                display: flex;\n                flex-direction: row;\n\n            }\n\n            .form-img {\n                display: inline;\n\n            }\n\n            .form-data {\n                margin: 4px;\n                font-size: 5px;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
