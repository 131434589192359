import { getDownloadURL, getMetadata, getStorage, listAll, ref } from "firebase/storage";
import React, { useEffect, useState } from 'react';
import Ai from "../assets/ai.png";
import Alert from "../assets/alert.png";
import Found from "../assets/found.png";
import GalleryIcon from "../assets/galleryicon.png";
import app from '../config/firebasecloud.js';
import ImageModal from './Modal.js'; 
import './tab.scss';

function Tabbar({ activeTopTab, setActiveTopTab }) {
    const [items, setItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false); // State to control modal visibility
    const [modalImageSrc, setModalImageSrc] = useState(''); // State to hold the image source for the modal

    useEffect(() => {
        const fetchMediaDetails = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, `${activeTopTab}/`);

                const res = await listAll(listRef);
                const details = await Promise.all(res.items.map(async (itemRef) => {
                    const metadata = await getMetadata(itemRef);
                    const url = await getDownloadURL(itemRef);

                    return {
                        id: itemRef.fullPath,
                        name: metadata.name,
                        description: metadata.customMetadata?.description || 'No description',
                        size: metadata.size,
                        contentType: metadata.contentType,
                        updated: metadata.updated,
                        url: url,
                        checked: false,
                    };
                }));

                setItems(details);
                console.log(details);
            } catch (err) {
                console.error("Error fetching media details:", err);
            }
        };

        fetchMediaDetails();
    }, [activeTopTab]);

    const handleTabClick = (e, tabIndex) => {
        e.preventDefault();
        setActiveTopTab(tabIndex);
    };

    const openModal = (imageSrc) => {
        setModalImageSrc(imageSrc);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalImageSrc('');
    };

    return (
        <div className="tabs">
            <ul className="tabs-nav">
                {[1, 2, 3].map((tabIndex) => (
                    <li
                        key={`top-tab-${tabIndex}`}
                        className={activeTopTab === tabIndex ? 'tab-active' : ''}
                    >
                        <a href="/" onClick={(e) => handleTabClick(e, tabIndex)}>
                            HN - 116{tabIndex}
                        </a>
                    </li>
                ))}
            </ul>
            <div className="tabs-stage">
                <div className="tab-content active">
                    <div className="cards">
                        {items.map((item) => (
                            <div className='card' key={item.id}>
                                <div className='card-container'>
                                    <img
                                        className='card-image'
                                        width={160}
                                        height={120}
                                        src={item.url}
                                        alt={`Content for ${item.name}`}
                                       // Open modal on image click
                                    />
                                    <div className="overlay" onClick={() => openModal(item.url)}>
                                        <img src={GalleryIcon} alt="Gallery icon" />
                                    </div>
                                </div>
                                <p className='card-content'>{item.name}</p>
                            </div>
                        ))}
                    </div>
                    <div className="table_component" role="region" tabIndex="0">
                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Image</th>
                                    <th>Form Data</th>
                                    <th>Add. Details</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <div className="table-row">
                                                <img
                                                    src={Ai}
                                                    alt='Ai'
                                                    onClick={() => openModal(Ai)} // Open modal on image click
                                                />
                                                <div className="table-data">
                                                    <div className="table-head">{item.name}</div>
                                                    <div className="table-text">{item.description}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-row">
                                                <img src={Found} alt='Found' />
                                                <div className="table-data">
                                                    <div className="table-head">Size: {item.size} bytes</div>
                                                    <div className="table-text">{new Date(item.updated).toLocaleDateString()}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="table-row">
                                                <img src={Alert} alt='Found' />
                                                <div className="table-data">
                                                    <div className="table-head">Size: {item.size} bytes</div>
                                                    <div className="table-text">{new Date(item.updated).toLocaleDateString()}</div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="status">
                                                <div className="status-text">Claimed</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Render the ImageModal component */}
            <ImageModal isOpen={modalOpen} imageSrc={modalImageSrc} onClose={closeModal} />
        </div>
    );
}

export default Tabbar;
