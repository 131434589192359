import React from 'react';
// import Lock from "../assets/squarelock2.png";
import "./locker.scss";

const Locker = () => {
    return (
        <div className='locker-container'>
            <div class="lock">
                {/* <img src={Lock} alt="" /> */}
            </div>
            <div class="lock-content">

                <div className="locker-name">
                    Locker
                </div>
                <div className="locker-box">
                    <div className="locker">
                        OFF
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Locker;