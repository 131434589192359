import React from 'react';
import "./boxes.scss";

const Boxes = () => {
    return (
        <div className='boxes'>
            <div class="count">
                <div class="box">
                    17
                </div>
                <div class="total">/25</div>
            </div>
            <div class="active">
                Active Boxes
            </div>
        </div>
    )
}

export default Boxes;
