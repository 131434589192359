// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-container {
  border-radius: 10px;
  background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around; }
  .status-container .status-name {
    color: #E8DCD0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 11px; }
  .status-container .percentage-box .percentage {
    margin: 11px;
    color: #E8DCD0;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/status.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,6DAA6D;EAC7D,aAAa;EAEb,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,6BAA6B,EAAA;EARjC;IAWQ,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,YAAY,EAAA;EAjBpB;IAsBY,YAAY;IACZ,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA","sourcesContent":[".status-container {\n    border-radius: 10px;\n    background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);\n    display: flex;\n    // margin: 10px;\n    flex-direction: column;\n    width: 100%;\n    height: 100%;\n    justify-content: space-around;\n\n    .status-name {\n        color: #E8DCD0;\n\n        font-size: 14px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n        margin: 11px;\n    }\n\n    .percentage-box {\n        .percentage {\n            margin: 11px;\n            color: #E8DCD0;\n\n            font-size: 30px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: normal;\n        }\n\n        // .chart {}\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
