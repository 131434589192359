// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solar-container {
  width: 88%;
  margin-left: 30px;
  height: 232px;
  border-radius: 10px;
  background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .solar-container .solar-header {
    color: #E8DCD0;
    font-size: 20px;
    padding: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; }
  .solar-container .solar-analysis {
    display: flex;
    flex-direction: column;
    gap: 10px; }
    .solar-container .solar-analysis .line {
      margin: 0px 16px 30px; }
    .solar-container .solar-analysis .solcount-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-right: 12px;
      color: #E8DCD0;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; }
      .solar-container .solar-analysis .solcount-container .solar-count {
        color: #E8DCD0;
        font-size: 38px;
        font-style: normal;
        margin-left: 12px;
        font-weight: 400;
        line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/solar.scss"],"names":[],"mappings":"AAAA;EACI,UAAU;EACV,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,6DAA6D;EAC7D,aAAa;EACb,sBAAsB;EACtB,8BAA8B,EAAA;EARlC;IAWQ,cAAc;IAEd,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EAjB3B;IAsBQ,aAAa;IACb,sBAAsB;IACtB,SAAS,EAAA;IAxBjB;MA2BY,qBAAqB,EAAA;IA3BjC;MAgCY,aAAa;MACb,mBAAmB;MACnB,mBAAmB;MACnB,8BAA8B;MAC9B,kBAAkB;MAClB,cAAc;MAEd,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB,EAAA;MA1C/B;QA6CgB,cAAc;QAEd,eAAe;QACf,kBAAkB;QAClB,iBAAiB;QACjB,gBAAgB;QAChB,mBAAmB,EAAA","sourcesContent":[".solar-container {\n    width: 88%;\n    margin-left: 30px;\n    height: 232px;\n    border-radius: 10px;\n    background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    .solar-header {\n        color: #E8DCD0;\n\n        font-size: 20px;\n        padding: 20px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n    }\n\n    .solar-analysis {\n\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n\n        .line {\n            margin: 0px 16px 30px;\n\n        }\n\n        .solcount-container {\n            display: flex;\n            flex-direction: row;\n            align-items: center;\n            justify-content: space-between;\n            margin-right: 12px;\n            color: #E8DCD0;\n\n            font-size: 18px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: normal;\n\n            .solar-count {\n                color: #E8DCD0;\n\n                font-size: 38px;\n                font-style: normal;\n                margin-left: 12px;\n                font-weight: 400;\n                line-height: normal;\n            }\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
