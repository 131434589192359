// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cssportals-grid {
  display: grid;
  grid-template-rows: 10% 1fr;
  grid-template-columns: 3% 1fr 20%;
  gap: 4px;
  width: 100%;
  height: 100vh; }

#profile-box {
  grid-area: 1/3/2/7; }

#adsbox {
  grid-area: 2 / 3 / 3 / 8; }

#navbar-box {
  grid-area: 1 / 1 / 2 / 3; }

#tabs {
  grid-area: 2/2/3/3; }

#menubar {
  grid-area: 2/1/3/2; }
`, "",{"version":3,"sources":["webpack://./src/display.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,2BAA2B;EAC3B,iCAAiC;EACjC,QAAQ;EACR,WAAW;EACX,aAAa,EAAA;;AAGjB;EACI,kBAAkB,EAAA;;AAItB;EACI,wBAAwB,EAAA;;AAG5B;EACI,wBAAwB,EAAA;;AAI5B;EACI,kBAAkB,EAAA;;AAGtB;EACI,kBAAkB,EAAA","sourcesContent":["#cssportals-grid {\n    display: grid;\n    grid-template-rows: 10% 1fr;\n    grid-template-columns: 3% 1fr 20%;\n    gap: 4px;\n    width: 100%;\n    height: 100vh;\n}\n\n#profile-box {\n    grid-area: 1/3/2/7;\n\n}\n\n#adsbox {\n    grid-area: 2 / 3 / 3 / 8;\n}\n\n#navbar-box {\n    grid-area: 1 / 1 / 2 / 3;\n\n}\n\n#tabs {\n    grid-area: 2/2/3/3;\n}\n\n#menubar {\n    grid-area: 2/1/3/2;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
