// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxes {
  width: 210px;
  height: 160px;
  border-radius: 14px;
  background: linear-gradient(180deg, rgba(21, 20, 26, 0.9) 0%, #2E2D33 100%);
  margin: auto; }
  .boxes .count {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 12px; }
    .boxes .count .box {
      color: #E8DCD0;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; }
    .boxes .count .total {
      color: #E8DCD0;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; }
  .boxes .active {
    padding: 1px 12px;
    color: #E8DCD0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/boxes.scss"],"names":[],"mappings":"AAAA;EACI,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,2EAA4E;EAC5E,YAAY,EAAA;EALhB;IAQQ,aAAa;IACb,8BAA8B;IAC9B,qBAAqB;IACrB,aAAa,EAAA;IAXrB;MAcY,cAAc;MAEd,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB,EAAA;IAnB/B;MAuBY,cAAc;MAEd,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB,EAAA;EA5B/B;IAiCQ,iBAAiB;IACjB,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA","sourcesContent":[".boxes {\n    width: 210px;\n    height: 160px;\n    border-radius: 14px;\n    background: linear-gradient(180deg, rgba(21, 20, 26, 0.90) 0%, #2E2D33 100%);\n    margin: auto;\n\n    .count {\n        display: flex;\n        justify-content: space-between;\n        align-items: flex-end;\n        padding: 12px;\n\n        .box {\n            color: #E8DCD0;\n\n            font-size: 35px;\n            font-style: normal;\n            font-weight: 600;\n            line-height: normal;\n        }\n\n        .total {\n            color: #E8DCD0;\n\n            font-size: 13px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: normal;\n        }\n    }\n\n    .active {\n        padding: 1px 12px;\n        color: #E8DCD0;\n\n        font-size: 12px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
