import React, { useState } from 'react';
import "./navbar.scss";

const Navbar = () => {

    const options = ['Hyderabad'];


    return (
        <div className='navbar'>
            <div className="menunav">
                <div className="left">
                    <div className="ham">
                        {/* SVG and other elements */}
                    </div>
                    <div className="device">
                        Device Dashboard
                    </div>
                </div>
                <div className="right">
                    <div className="box">
                        <div className="location">Location</div>
                    </div>
                    <div className="dropdown">
                        <form>
                            <select id="dropdown">
                                <option value="" disabled>Select</option>
                                {options.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
