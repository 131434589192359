// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.locker-container {
  border-radius: 10px;
  background: linear-gradient(180deg, #CD4D47 0%, #9A3A35 39.25%, #672724 86.5%);
  display: flex;
  margin: 0px 12px;
  flex-direction: row-reverse;
  width: 90%;
  height: 100%;
  justify-content: space-between;
  align-items: center; }
  .locker-container .lock {
    width: 100%; }
    .locker-container .lock img {
      z-index: 1;
      position: relative;
      width: 100px;
      height: 100%;
      right: -37px; }
      @media (min-width: 1024px) {
        .locker-container .lock img {
          right: -65px; } }
  .locker-container .lock-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around; }
  .locker-container .locker-name {
    color: #E8DCD0;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 11px;
    z-index: 2; }
  .locker-container .locker-box .locker {
    color: #E1C2C1;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    margin-left: 10px;
    line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/locker.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB;EACnB,8EAA8E;EAC9E,aAAa;EACb,gBAAgB;EAChB,2BAA2B;EAC3B,UAAU;EACV,YAAY;EACZ,8BAA8B;EAC9B,mBAAmB,EAAA;EATvB;IAYQ,WAAW,EAAA;IAZnB;MAeY,UAAU;MACV,kBAAkB;MAClB,YAAY;MACZ,YAAY;MACZ,YAAY,EAAA;MAGZ;QAtBZ;UAuBgB,YAAY,EAAA,EAEnB;EAzBT;IA6BQ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,6BAA6B,EAAA;EAjCrC;IAqCQ,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,UAAU,EAAA;EA5ClB;IAiDY,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB,EAAA","sourcesContent":[".locker-container {\n    border-radius: 10px;\n    background: linear-gradient(180deg, #CD4D47 0%, #9A3A35 39.25%, #672724 86.5%);\n    display: flex;\n    margin: 0px 12px;\n    flex-direction: row-reverse;\n    width: 90%;\n    height: 100%;\n    justify-content: space-between;\n    align-items: center;\n\n    .lock {\n        width: 100%;\n\n        img {\n            z-index: 1;\n            position: relative;\n            width: 100px;\n            height: 100%;\n            right: -37px; // Default value\n\n            // Media query for screens wider than 1024px\n            @media (min-width: 1024px) {\n                right: -65px;\n            }\n        }\n    }\n\n    .lock-content {\n        width: 100%;\n        height: 100%;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-around;\n    }\n\n    .locker-name {\n        color: #E8DCD0;\n\n        font-size: 16px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n        margin-left: 11px;\n        z-index: 2;\n    }\n\n    .locker-box {\n        .locker {\n            color: #E1C2C1;\n\n            font-size: 42px;\n            font-style: normal;\n            font-weight: 400;\n            margin-left: 10px;\n            line-height: normal;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
