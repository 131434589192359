import { deleteObject, getDownloadURL, getMetadata, getStorage, listAll, ref, uploadBytes } from "firebase/storage";
import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"; // Import left and right arrow icons
import { MdDeleteOutline } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS for styling
import AdImage from "../assets/ad-image.png";
import Line from "../assets/linedraw.png";
import Box from "../assets/smallbox.png";
import Upload from "../assets/upload.png";
import Button from "../components/Button.js";
import app from '../config/firebasecloud.js';
import "./adsbox.scss";

const Adsbox = ({ TabValue }) => {
    const [uploadedMedia, setUploadedMedia] = useState(null);
    const [mediaFile, setMediaFile] = useState(null); // Keep track of the original file
    const [uploading, setUploading] = useState(false);
    const [mediaURL, setMediaURL] = useState(null);
    const [currentDisplay, setCurrentDisplay] = useState(1); // State to track current display
    const [isMediaSelected, setIsMediaSelected] = useState(false); // State to track if an image or video is selected
    const fileInputRef = useRef(null); // Reference to the file input
    const [deleting, setDeleting] = useState(null); // State to track deleting process

    const [items, setItems] = useState([]); // Initialize items as empty array

    useEffect(() => {
        const fetchMediaDetails = async () => {
            try {
                const storage = getStorage(app);
                const listRef = ref(storage, `${TabValue}/`); // Reference to the directory named by TabValue

                // List all files in the directory
                const res = await listAll(listRef);
                const details = await Promise.all(res.items.map(async (itemRef) => {
                    // Get metadata for each file
                    const metadata = await getMetadata(itemRef);
                    return {
                        id: itemRef.fullPath, // Ensure this is a string path
                        name: metadata.name,
                        description: metadata.customMetadata?.description || 'No description',
                        size: metadata.size,
                        contentType: metadata.contentType,
                        updated: metadata.updated,
                        checked: false,
                    };
                }));

                setItems(details); // Set items to mediaDetails fetched from Firebase
                console.log(details);
            } catch (err) {
                console.error("Error fetching media details:", err);
            }
        };

        fetchMediaDetails();
    }, [TabValue]);

    const handleMediaChange = (e) => {
        const media = e.target.files[0];
        if (media) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUploadedMedia(reader.result);
                setMediaFile(media); // Store the original file
                setIsMediaSelected(true); // Mark that a media file is selected
            };
            reader.readAsDataURL(media);
        }
    };

    // Function to handle deleting media from Firebase Storage

    const handleDelete = async (filePath) => {
        try {
            setDeleting(filePath); // Set deleting state to show loading indicator
            const storage = getStorage(app);
            const fileRef = ref(storage, filePath); // Ensure filePath is a valid string path
            await deleteObject(fileRef); // Delete the file from Firebase Storage

            // Remove the deleted item from the state
            setItems(items.filter(item => item.id !== filePath));

            // Show success notification
            toast.success('Media deleted successfully!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        } catch (err) {
            console.error("Error deleting media:", err);
            // Show error notification
            toast.error('Failed to delete media!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        } finally {
            setDeleting(null); // Reset deleting state
        }
    };

    const handleUploadMedia = async () => {
        if (!uploadedMedia || !mediaFile) return;
        try {
            setUploading(true);
            const storage = getStorage(app);
            const storageRef = ref(storage, `${TabValue}/${mediaFile.name}`); // Save using original file name in TabValue folder
            const response = await fetch(uploadedMedia);
            const blob = await response.blob();
            await uploadBytes(storageRef, blob);
            const downloadURL = await getDownloadURL(storageRef);
            setMediaURL(downloadURL);

            // Show success notification
            toast.success('Media uploaded successfully!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        } catch (err) {
            console.log(err);
            // Show error notification
            toast.error('Failed to upload media!', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        } finally {
            setUploading(false);
            setIsMediaSelected(false); // Reset after uploading
        }
    };

    const handleCancel = () => {
        setUploadedMedia(null); // Clear the selected media
        setMediaFile(null); // Clear the stored file
        setIsMediaSelected(false);
    };

    // Function to handle display change
    const handleDisplayChange = (direction) => {
        if (direction === 'left' && currentDisplay > 1) {
            setCurrentDisplay(currentDisplay - 1);
        } else if (direction === 'right' && currentDisplay < 3) {
            setCurrentDisplay(currentDisplay + 1);
        }
    };

    // Function to handle checkbox change
    const handleCheckboxChange = (id) => {
        const updatedItems = items.map((item) =>
            item.id === id ? { ...item, checked: !item.checked } : item
        );
        setItems(updatedItems);
    };

    // Function to handle Upload Ads button click
    const handleUploadAds = () => {
        const checkedItems = items.filter(item => item.checked).map(item => item.name);
        console.log('Checked items:', checkedItems);
    };

    return (
        <div className='adsbox'>
            <ToastContainer /> {/* Toast container to display notifications */}
            <div className="heading">
                <div className="box">
                    <img src={Box} width={87} height={70} alt="box" />
                </div>
                <div className="boxname">
                    HN - 116{TabValue}
                    <br />
                    <div class="address">
                        <div class="address-cont">
                            rd.no 12, <br />
                            hi-tech city
                        </div>
                    </div>
                </div>
            </div>
            <div className="navigation">
                <AiOutlineLeft
                    onClick={() => handleDisplayChange('left')}
                    className={`arrow ${currentDisplay <= 1 ? 'disabled' : ''}`} // Add 'disabled' class if display is less than or equal to 1
                />
                <div className="boxname">
                    Display - {currentDisplay}
                </div>
                <AiOutlineRight
                    onClick={() => handleDisplayChange('right')}
                    className={`arrow ${currentDisplay >= 3 ? 'disabled' : ''}`}
                />
            </div>
            <img src={Line} alt="line" />

            {/* Media with overlay to choose file */}
            <div className="img-container">
                <img src={uploadedMedia ? uploadedMedia : AdImage} alt="ad" className="ad-image" />
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleMediaChange}
                />
                <div className="overlay" onClick={() => fileInputRef.current.click()}> {/* Trigger file input on click */}
                    <img src={Upload} alt="gallery icon" />
                </div>
            </div>
            {/* Save and Cancel buttons */}
            {isMediaSelected && (
                <div className="button-info">
                    <Button content={"Upload"} onClick={handleUploadMedia} />
                    <MdDeleteOutline
                        className="upload-icon" aria-hidden="true" onClick={handleCancel} size={24} />
                </div>
            )}

            <img src={Line} alt="line" />
            <div className="header">
                ADS
            </div>
            <ul className="listbox">
                {items.map((item, index) => (
                    <li key={item.id} className="list">
                        {/* <input
                            type="checkbox"
                            className="checkbox"
                            checked={item.checked}
                            onChange={() => handleCheckboxChange(item.id)}
                        /> */}
                        <div className="count">
                            <div className="number">{index + 1}</div>
                        </div>
                        <div className="content">
                            <div className="adname">
                                {item.name.length > 20 ? `${item.name.substring(0, 20)}..` : item.name}
                            </div>
                            <div className="addes">{item.contentType}</div>
                        </div>
                        <MdDeleteOutline
                            className="delete-icon"
                            aria-hidden="true"
                            size={24}
                            onClick={() => handleDelete(item.id)} // Pass the correct path to handleDelete
                            disabled={deleting === item.id} // Disable button while deleting
                        />
                        {deleting === item.id && <span className="loading-text">...</span>}
                    </li>
                ))}
            </ul>
            {/* <div className="button-info">
                <Button content={"Upload Ads"} onClick={handleUploadAds} />
            </div> */}
        </div>
    );
};

export default Adsbox;
