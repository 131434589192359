// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-container {
  background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  max-height: -moz-fit-content;
  max-height: fit-content; }
  .item-container .item-header {
    width: 100%;
    text-align: left;
    color: #E8DCD0;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; }
  .item-container .piechart {
    width: 100%;
    margin: auto; }
`, "",{"version":3,"sources":["webpack://./src/components/item.scss"],"names":[],"mappings":"AAAA;EACI,6DAA6D;EAC7D,mBAAmB;EAEnB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EAEnB,8BAA8B;EAC9B,aAAa;EACb,4BAAuB;EAAvB,uBAAuB,EAAA;EAV3B;IAaQ,WAAW;IACX,gBAAgB;IAChB,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EApB3B;IAyBQ,WAAW;IACX,YAAY,EAAA","sourcesContent":[".item-container {\n    background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);\n    border-radius: 10px;\n    // margin: 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    // margin-left: 30px;\n    justify-content: space-between;\n    padding: 12px;\n    max-height: fit-content;\n\n    .item-header {\n        width: 100%;\n        text-align: left;\n        color: #E8DCD0;\n\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 600;\n        line-height: normal;\n        // margin: 12px;\n    }\n\n    .piechart {\n        width: 100%;\n        margin: auto;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
