import React from 'react';
import "./item.scss";
const Items = () => {
    return (
        <div className='item-container'>
            <div className="item-header">
                Items
            </div>

            <div class="pie-chart">
                <svg xmlns="http://www.w3.org/2000/svg" width="140" height="100" viewBox="0 0 101 102" fill="none">
                    <path d="M45.3133 87.4289C53.3544 88.6025 61.5579 87.0815 68.6437 83.1032C75.7296 79.1249 81.2994 72.9131 84.4842 65.4368C87.6689 57.9606 88.2895 49.6405 86.249 41.7745C84.2086 33.9086 79.6219 26.9392 73.2046 21.9538" stroke="#100F15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M87.3032 49.3899C87.3032 41.8148 85.0569 34.4099 80.8484 28.1115C76.64 21.8131 70.6583 16.9041 63.6599 14.0053C56.6615 11.1064 48.9607 10.348 41.5312 11.8258C34.1017 13.3036 27.2773 16.9513 21.9209 22.3077" stroke="url(#paint0_linear_1175_6427)" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M31.7218 15.1074C24.1051 18.7838 17.9195 24.8779 14.13 32.439C10.3406 40 9.16034 48.6028 10.7735 56.905C12.3867 65.2073 16.7026 72.7421 23.0477 78.3339C29.3929 83.9257 37.4105 87.26 45.8497 87.8167" stroke="#E85D56" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
                    <defs>
                        <linearGradient id="paint0_linear_1175_6427" x1="49.0031" y1="13.5898" x2="49.0031" y2="85.1899" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#E85D56" />
                            <stop offset="0.145" stop-color="#823430" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </div>
    )
}

export default Items;