import React, { useState } from 'react';
import Ads from './components/Ads.js';
import Boxes from './components/Boxes.js';
import Device from './components/Device.js';
import FormData from './components/FormData.js';
import ItemClaim from './components/ItemClaim.js';
import Items from './components/Items.js';
import Locker from './components/Locker.js';
import Main from './components/Main.js';
import Map from "./components/Map.js";
import Menu from './components/Menu.js';
import Navbar from './components/Navbar.js';
import Profile from './components/Profile.js';
import Solar from './components/Solar.js';
import Status from './components/Status.js';
import "./layout.scss";

const Layout = () => {
    const [selectedLocation, setSelectedLocation] = useState('');
    return (
        // <div id="cssportal-grid">
        //     <div id="device"></div>
        //     <div id="navbar"><Navbar /></div>
        //     <div id="profile"><Profile /></div>
        //     <div id="menu"><Menu /></div>
        //     <div id="status"></div>
        //     <div id="locker"><Locker/></div>
        //     <div id="itemrem"><Items/></div>
        //     <div id="itemcla"><ItemClaim/></div>
        //     <div id="solar"><Solar/></div>
        //     <div id="form"><FormData/></div>
        //     <div id="main"><Main /></div>
        //     <div id="map"><Map /></div>
        //     <div id="boxes"><Boxes /></div> 
        //     <div id="ads"><Ads /></div>
        // </div>
        <div class="parent">
            <div class="div1"> <Navbar setSelectedLocation={setSelectedLocation} /></div>
            <div class="div2"><Profile /></div>
            <div class="div3"><Menu /></div>
            <div class="div4"><Map selectedLocation={selectedLocation} /></div>
            <div class="div5"><Main /></div>
            <div class="div6"><Boxes /></div>
            <div class="div7"><Ads /></div>
            <div class="div8"><Device /></div>
            <div class="div9">
                <Items />
            </div>
            <div class="div10"><Locker /></div>
            <div class="div11">
                <ItemClaim />
            </div>
            {/* <div class="div12"></div> */}
        </div>
    )
}

export default Layout;