import React from 'react';
import Line from "../assets/Line.png";
import Chart from "./Chart.js";
import "./main.scss";
const Main = () => {
    return (
        <div className='main'>
            <div class="top">
                <div className="heading">Daily Interactions</div>
            </div>
            <div className="bottom">
                <div className="graph-box">
                    <div className="number">
                        76,671
                    </div>
                    <div className="graph">
                        <Chart />
                    </div>
                </div>
                <img src={Line} alt="" />
            </div>

        </div>
    )
}

export default Main