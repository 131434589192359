// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu {
  margin-top: 50px;
  display: flex; }
  .menu .icons {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px; }
    .menu .icons .icon {
      display: flex;
      gap: 6px;
      flex-direction: column;
      align-items: center;
      margin: 5px; }
    .menu .icons .text {
      color: #D9D9D9;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/menu.scss"],"names":[],"mappings":"AAAA;EACI,gBAAgB;EAChB,aAAa,EAAA;EAFjB;IAKQ,aAAa;IACb,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,SAAS,EAAA;IAVjB;MAaY,aAAa;MACb,QAAQ;MACR,sBAAsB;MACtB,mBAAmB;MACnB,WAAW,EAAA;IAjBvB;MAsBY,cAAc;MAEd,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB,EAAA","sourcesContent":[".menu {\n    margin-top: 50px;\n    display: flex;\n\n    .icons {\n        display: flex;\n        width: 100%;\n        height: 100%;\n        flex-direction: column;\n        align-items: center;\n        gap: 20px;\n\n        .icon {\n            display: flex;\n            gap: 6px;\n            flex-direction: column;\n            align-items: center;\n            margin: 5px;\n\n        }\n\n        .text {\n            color: #D9D9D9;\n\n            font-size: 11px;\n            font-style: normal;\n            font-weight: 400;\n            line-height: normal;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
