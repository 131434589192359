// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Itemclaim-container {
  background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);
  border-radius: 10px;
  width: 97%;
  height: 102%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 20px;
  /* margin: auto; */ }
  .Itemclaim-container .claim-header {
    color: #E8DCD0;
    font-size: 18px;
    font-style: normal;
    margin: 12px;
    font-weight: 600;
    line-height: normal; }
  .Itemclaim-container .claim-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: 12px;
    color: #E8DCD0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
    .Itemclaim-container .claim-container .claim-count {
      color: #E8DCD0;
      font-size: 38px;
      font-style: normal;
      margin-left: 12px;
      margin-bottom: 12px;
      font-weight: 400;
      line-height: normal; }
`, "",{"version":3,"sources":["webpack://./src/components/itemclaim.scss"],"names":[],"mappings":"AACA;EACQ,6DAA6D;EAC7D,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,SAAS;EACT,kBAAA,EAAmB;EAT3B;IAYQ,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB,EAAA;EAlB3B;IAuBQ,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,cAAc;IAEd,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;IAjC3B;MAoCY,cAAc;MAEd,eAAe;MACf,kBAAkB;MAClB,iBAAiB;MACjB,mBAAmB;MACnB,gBAAgB;MAChB,mBAAmB,EAAA","sourcesContent":["\n.Itemclaim-container {\n        background: linear-gradient(180deg, #2F2F32 0%, #171718 100%);\n        border-radius: 10px;\n        width: 97%;\n        height: 102%;\n        display: flex;\n        flex-direction: column;\n        justify-content: space-around;\n        gap: 20px;\n        /* margin: auto; */\n\n    .claim-header {\n        color: #E8DCD0;\n\n        font-size: 18px;\n        font-style: normal;\n        margin: 12px;\n        font-weight: 600;\n        line-height: normal;\n    }\n\n    .claim-container {\n\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        justify-content: space-between;\n        margin-right: 12px;\n        color: #E8DCD0;\n\n        font-size: 18px;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n\n        .claim-count {\n            color: #E8DCD0;\n\n            font-size: 38px;\n            font-style: normal;\n            margin-left: 12px;\n            margin-bottom: 12px;\n            font-weight: 400;\n            line-height: normal;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
