// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
    apiKey: "AIzaSyB0VtEXGL5UR3qLKK91jj3GuCa-2jNpqyg",
    authDomain: "mm-video-2ecac.firebaseapp.com",
    projectId: "mm-video-2ecac",
    storageBucket: "mm-video-2ecac.appspot.com",
    messagingSenderId: "827160406522",
    appId: "1:827160406522:web:e5311d6421bbd48e9fdc43",
    measurementId: "G-2CNGXD55GQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;