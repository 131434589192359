import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Dev from "../assets/box.png";
import Locations from "../assets/location.png";
import Button from "./Button.js";
import "./device.scss";



const Device = () => {
    const navigate = useNavigate();

    return (
        <div className='device-bg'>
            <div class="container">
                <div class="device">
                    <img src={Dev} height={250} alt="Device" />
                </div>
                <div class="content">
                    <div class="device-id">
                        HN-1116
                    </div>
                    <div class="address">
                        <div class="location">
                            <img src={Locations} alt="" />
                        </div>
                        <div class="address-cont">
                            rd.no 12, <br />
                            hi-tech city
                        </div>
                    </div>

                    <div className="grid-container">
                        <div className="grid-item">
                            <div class="grid-number"> OFF</div>
                            <div class="grid-content">
                                Display
                            </div>

                        </div>
                        <div className="grid-item">
                            <div class="grid-number"> 14</div>
                            <div class="grid-content">
                                Items
                            </div>

                        </div>
                        <div className="grid-item">
                            <div class="grid-number"> 56%</div>
                            <div class="grid-content">
                                Status
                            </div>

                        </div>
                    </div>
                    <div class="button-info">
                        <Link to="/display">
                            <Button content={"Device Info"} />
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Device;
