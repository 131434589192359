import React from 'react';
import FormLine from "../assets/Lineform.png";
import Vector from "../assets/devicevectorpng.png";
import FormImg from "../assets/form-img.png";
import "./formdata.scss";

const FormData = () => {
    return (
        <div className='form-container'>
            <div class="device-svg">
                <svg width="230" height="112" viewBox="0 0 230 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="158.938" y1="73.7402" x2="158.938" y2="0.331573" stroke="#100F15" />
                    <line x1="158.516" y1="44.0645" x2="230" y2="44.0645" stroke="#100F15" />
                    <line y1="29.9395" x2="153.938" y2="29.9395" stroke="#100F15" />
                    <path d="M161.671 97.6913L142.651 12.123H210.159L195.853 97.6913L161.671 97.6913Z" fill="#D24B46" stroke="black" />
                    <line x1="181.016" y1="109.443" x2="181.016" y2="11.5648" stroke="#100F15" />
                    <path d="M169.344 111.17L173.52 100.879H189.34L193.515 111.17H169.344Z" fill="#E85D56" stroke="black" />
                </svg>

            </div>
            <div class="form-card">
                <div class="device-id">
                    Pl-9823123
                </div>
                <div class="form-content">

                    <div class="linevector">
                        <img src={FormLine} alt="" />

                    </div>
                    <div class="cont">

                        <div class="form-img">
                            <img src={FormImg} alt="" />
                        </div>
                        <div class="form-data">
                            <b>
                                Lorem ipsum
                            </b>
                                <br />
                            dolor sit amet, consectetur adipiscdsj hjngnnbf hjg hggf8p mklkop ytf...

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-card">
                <div class="device-id">
                    Pl-9823123
                </div>
                <div class="form-content">

                    <div class="linevector">
                        <img src={FormLine} alt="" />

                    </div>
                    <div class="cont">

                        <div class="form-img">
                            <img src={FormImg} alt="" />
                        </div>
                        <div class="form-data">
                            <b>
                                Lorem ipsum
                            </b>
                                <br />
                            dolor sit amet, consectetur adipiscdsj hjngnnbf hjg hggf8p mklkop ytf...

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-card">
                <div class="device-id">
                    Pl-9823123
                </div>
                <div class="form-content">

                    <div class="linevector">
                        <img src={FormLine} alt="" />

                    </div>
                    <div class="cont">

                        <div class="form-img">
                            <img src={FormImg} alt="" />
                        </div>
                        <div class="form-data">
                            <b>
                                Lorem ipsum
                            </b>
                                <br />
                            dolor sit amet, consectetur adipiscdsj hjngnnbf hjg hggf8p mklkop ytf...

                        </div>
                    </div>
                </div>
            </div>
            <div class="form-card">
                <div class="device-id">
                    Pl-9823123
                </div>
                <div class="form-content">

                    <div class="linevector">
                        <img src={FormLine} alt="" />

                    </div>
                    <div class="cont">

                        <div class="form-img">
                            <img src={FormImg} alt="" />
                        </div>
                        <div class="form-data">
                            <b>
                                Lorem ipsum
                            </b>
                                <br />
                            dolor sit amet, consectetur adipiscdsj hjngnnbf hjg hggf8p mklkop ytf...

                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FormData;