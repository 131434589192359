import LinearProgress from '@mui/joy/LinearProgress';
import Stack from '@mui/joy/Stack';
import * as React from 'react';
import "./ads.scss";

export default function LinearProgressDeterminate() {
    const [progress, setProgress] = React.useState(0);

    return (
        <Stack spacing={2} sx={{ flex: 1 }}>
            <LinearProgress determinate value={15} />
        </Stack>
    );
}