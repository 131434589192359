// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pac-input-target {
  z-index: 10 !important;
  color: #e8dcdc !important;
  background: linear-gradient(180deg, rgba(21, 20, 26, 0.9) 0%, #2E2D33 100%) !important;
  font-weight: bold !important; }
`, "",{"version":3,"sources":["webpack://./src/components/map.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB;EACtB,yBAAyB;EACzB,sFAAuF;EACvF,4BAA4B,EAAA","sourcesContent":[".pac-input-target {\n    z-index: 10 !important;\n    color: #e8dcdc !important;\n    background: linear-gradient(180deg, rgba(21, 20, 26, 0.90) 0%, #2E2D33 100%) !important;\n    font-weight: bold !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
