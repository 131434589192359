import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from "../assets/logomm.png";
import "./login.scss";

const Login = ({ onLogin }) => {
    const [email, setEmail] = useState("nikhila5601@gmail.com");
    const [password, setPassword] = useState("nikki");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        // Check if the email and password are correct
        if (email === 'nikhila5601@gmail.com' && password === 'nikki') {
            localStorage.setItem('email', 'Nikhila');
            onLogin();
            toast.info('Login Successful', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
            navigate("/home");
        } else {
            toast.error('Invalid email or password', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "dark",
            });
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="login-container">
                <div className="heading"></div>
                <div className="container">
                    <div className="logo-card"></div>
                    <div className="login-card">
                        <form onSubmit={handleLogin}>
                            <input
                                type="email"
                                placeholder='Email'
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                required
                            />
                            <input
                                type="password"
                                placeholder='Password'
                                onChange={e => setPassword(e.target.value)}
                                value={password}
                                required
                            />
                            <div className="row">
                                <div className="box">
                                    <p>Missing Matters</p>
                                    <div className="logo">
                                        <img src={Logo} width="28px" height="30px" alt="keto" />
                                    </div>
                                </div>
                                <button type="submit">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
