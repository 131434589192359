import React, { PureComponent } from 'react';
import { Bar, BarChart, ResponsiveContainer } from 'recharts';
import "./chart.scss";

const data = [
    {
        name: 'Page A',
        uv: 2000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 7000,
        pv: 1398,
        amt: 1210,
    },
    {
        name: 'Page C',
        uv: 8000,
        pv: 9800,
        amt: 2890,
    },
    {
        name: 'Page D',
        uv: 4000,
        pv: 9800,
        amt: 2890,
    }
];

export default class Chart extends PureComponent {
    state = {
        isHovered: false, // Track the hover state
    };

    handleMouseEnter = () => {
        this.setState({ isHovered: true });
    };

    handleMouseLeave = () => {
        this.setState({ isHovered: false });
    };

    render() {
        const { isHovered } = this.state;

        return (
            <ResponsiveContainer width={150} height={50}>
                <BarChart data={data} barSize={27}>
                    <Bar
                        dataKey="uv"
                        fill={isHovered ? '#e85d56' : '#E8DCD0'} // Change color to red on hover
                        radius={[4, 4, 4, 4]}
                       
                    />
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
