import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Image from "../assets/profileimg.png";
import "./profile.scss";

const Profile = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  // Retrieve email from local storage when the component mounts
  useEffect(() => {
    const storedEmail = localStorage.getItem('email') || 'Nikhila';
    setEmail(storedEmail);
  }, []);

  const role = localStorage.getItem('role') || 'ADMIN';

  // Handle logout
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  return (
    <div className='profile'>
      <img className='profile-img' src={Image} alt="Profile" />
      <div className="box">
        <div className="name">{email}</div>
        <div className="role">{role}</div>
      </div>
      <div className="name logout" onClick={handleLogout}>Logout</div>
    </div>
  );
}

export default Profile;