// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recharts-rectangle {
  border-radius: 20px; }

.recharts-rectangle:hover {
  fill: #e85d56; }
`, "",{"version":3,"sources":["webpack://./src/components/chart.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB,EAAA;;AAGvB;EACI,aAAa,EAAA","sourcesContent":[".recharts-rectangle {\n    border-radius: 20px;\n}\n\n.recharts-rectangle:hover {\n    fill: #e85d56;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
