import { useState } from 'react';
import Adsbox from './components/Adsbox';
import Menu from './components/Menu';
import Navbar from './components/Navbar';
import Profile from './components/Profile';
import Tabbar from './components/Tabs';
import "./display.scss";

const Display = () => {

    const [activeTopTab, setActiveTopTab] = useState(1);

    return (
        <div id="cssportals-grid">
            <div id="profile-box"><Profile /></div>
            <div id="adsbox"><Adsbox TabValue={activeTopTab} /></div>
            <div id="navbar-box"><Navbar /></div>
            <div id="tabs"><Tabbar activeTopTab={activeTopTab} setActiveTopTab={setActiveTopTab} /></div>
            <div id="menubar"><Menu /></div>
        </div>
    )
}

export default Display;
