import React from 'react';
import "./itemclaim.scss";

const ItemClaim = () => {
  return (
    <div className='Itemclaim-container'>
      <div class="claim-header">
        Items Claimed
      </div>
      <div class="claim-container">
        <div class="claim-count">
          38
        </div>
        / 40
      </div>
    </div>
  )
}

export default ItemClaim;