import { Autocomplete, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import "./map.scss";


const containerStyle = {
    width: '100%',
    height: '100%' // Adjust the height as needed
};

const points = [
    { lat: 17.437462, lng: 78.468288 },
    { lat: 17.468, lng: 78.5788 },
    { lat: 17.628, lng: 78.448288 },
];


const center = {
    lat: 17.385044,  // Hyderabad latitude
    lng: 78.486671   // Hyderabad longitude
};

const darkModeStyle = [
    { elementType: 'geometry', stylers: [{ color: '#212121' }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#212121' }] },
    { featureType: 'administrative', elementType: 'geometry', stylers: [{ color: '#757575' }] },
    { featureType: 'administrative.country', elementType: 'labels.text.fill', stylers: [{ color: '#9e9e9e' }] },
    { featureType: 'administrative.land_parcel', stylers: [{ visibility: 'off' }] },
    { featureType: 'administrative.locality', elementType: 'labels.text.fill', stylers: [{ color: '#bdbdbd' }] },
    { featureType: 'poi', elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
    { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#181818' }] },
    { featureType: 'poi.park', elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
    { featureType: 'poi.park', elementType: 'labels.text.stroke', stylers: [{ color: '#1b1b1b' }] },
    { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#2c2c2c' }] },
    { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#8a8a8a' }] },
    { featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: '#373737' }] },
    { featureType: 'road.highway', elementType: 'geometry', stylers: [{ color: '#3c3c3c' }] },
    { featureType: 'road.highway.controlled_access', elementType: 'geometry', stylers: [{ color: '#4e4e4e' }] },
    { featureType: 'road.local', elementType: 'labels.text.fill', stylers: [{ color: '#616161' }] },
    { featureType: 'transit', elementType: 'labels.text.fill', stylers: [{ color: '#757575' }] },
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#000000' }] },
    { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#3d3d3d' }] }
];

function MyComponent() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDawx-PwOvSV24Rkk4WPvvwe79gDDmWIyI",
        libraries: ['places']
    });

    const [customMarker, setCustomMarker] = useState(null);
    const [map, setMap] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const [selectedPosition, setSelectedPosition] = useState(null);
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (isLoaded && window.google) {
            setCustomMarker({
                url: '/device-marker.png',
                scaledSize: new window.google.maps.Size(50, 50),
            });
        }
    }, [isLoaded]);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
    }, []);

    const onAutocompleteLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            const location = place.geometry?.location;
            if (location) {
                const newPosition = {
                    lat: location.lat(),
                    lng: location.lng()
                };
                setSelectedPosition(newPosition);
                map.panTo(newPosition);
                map.setZoom(14);
            }
        }
    };

    return isLoaded ? (
        <div style={{ position: 'relative', height: '100%' }}>
            <Autocomplete
                onLoad={onAutocompleteLoad}
                onPlaceChanged={onPlaceChanged}
                fields={['geometry']}>
                <input
                    type="text"
                    placeholder="Search place"
                    style={{
                        boxSizing: 'border-box',
                        border: '1px solid transparent',
                        width: '240px',
                        height: '32px',
                        padding: '0 12px',
                        borderRadius: '3px',
                        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                        fontSize: '14px',
                        position: 'absolute',
                        left: '15%',
                        marginLeft: '-120px',
                        top: '10px',
                        zIndex: '10',
                    }}
                    ref={autocompleteRef}
                />
            </Autocomplete>

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={11} // Set a lower zoom level to show a broader area of Hyderabad
                onLoad={onLoad}
                onUnmount={onUnmount}
                options={{
                    streetViewControl: false,
                    mapTypeControl: false,
                    styles: darkModeStyle
                }}
            >
                {points.map((point, i) => (
                    <MarkerF key={i} position={point} icon={customMarker} />
                ))}
                {selectedPosition && <MarkerF position={selectedPosition} />}
            </GoogleMap>
        </div>
    ) : <></>;
}

export default React.memo(MyComponent);
