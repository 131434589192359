import React from 'react';
import "./ads.scss";
import Progress from "./Progress.js";

const Ads = () => {
    return (
        <div className='ads'>
            <div class="running">Ads Running</div>
            <div class="number">
                19
            </div>
            <div class="line">
                <Progress />
            </div>
            <div class="share">
                AD Share
            </div>
            <div class="brand">
                Louis Vuitton
            </div>
        </div>

    )
}

export default Ads;