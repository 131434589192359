import React, { useEffect, useState } from 'react';
import "./Buttoon.scss";

const Toggle = ({ content, onClick }) => {
    const [selected, setSelected] = useState(0);


    return (
        <button class="cssbuttons-io-button" onClick={onClick}> {content}
            <div class="icon">
                <svg width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="Group 1171275986">
                        <path id="Vector" d="M6.60789 1.5L1.07422 7" stroke="white" stroke-width="0.5" stroke-linecap="round" />
                        <path id="Vector_2" d="M1.07422 1.11274C1.07422 1.11274 5.93252 0.705692 6.66979 1.43847C7.40711 2.17125 6.99746 7 6.99746 7" stroke="white" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                </svg>

            </div>
        </button>

    );
};

export default Toggle;
