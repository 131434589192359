// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  width: 100vw;
  height: 100vh;
  background-color: #100F15; }
`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AACA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB,EAAA","sourcesContent":["\n.app {\n  width: 100vw;\n  height: 100vh;\n  background-color: #100F15;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
